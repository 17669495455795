import { Component, OnInit, ViewChild } from '@angular/core';
import { UploadAssetService } from '../../services/upload-asset.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';
import { TemplateDetails } from '../../models/asset.metadata.model';
import { MetadataService } from '@dal/core';
import { DialogComponent, DropDownComponent } from '@dal/shared-ui'

@Component({
  selector: 'dal-upload-page',
  templateUrl: './uploadPage.component.html',
  styleUrl: './uploadPage.component.scss',
})
export class UploadPageComponent implements OnInit {
  isFinishClicked: boolean = false;
  templates$: Observable<TemplateDetails[]>;
  heading: string;
  previousTemplate: TemplateDetails
  selectedTemplate: string;
  label: string;
  mediaType: string = this.uploadAssetService.currentTemplate.model;

  @ViewChild(DropDownComponent) DropDownComponent!: DropDownComponent;

  constructor(public uploadAssetService: UploadAssetService, public dialog: MatDialog, private _metadataService: MetadataService,
    private router: Router) {
  }

  
  ngOnInit() {
    this.templates$ = this._metadataService.getAllMetadataTemplates().pipe(
      map(templates => {
        return templates
          .filter((template: TemplateDetails) => template.model?.toLowerCase() === this.mediaType?.toLowerCase())
          .map((template: TemplateDetails) => {
            return {
              ...template,
              template_id: template.id,
              template_name: `${template.source} ${template.name} ${template.bu_name}`,
            };
          });
      })
    );

    this.selectedTemplate = this.uploadAssetService.currentTemplate.template_name ? this.uploadAssetService.currentTemplate.template_name : '';
    console.log(this.selectedTemplate);
    this.heading = `DAL ${this.mediaType?.toUpperCase()} TEMPLATES`;
    this.label = `Select a DAL ${this.mediaType} Template`;

    if (this.uploadAssetService.files.length === 0) {
      this.cancel()
    }
  }
  checkIfFormIsValid() {
    return this.uploadAssetService.assetPreviewForm?.valid && this.uploadAssetService.files.length > 0 && this.uploadAssetService.metadataForm.valid
  }

  submit() {
    this.isFinishClicked = true;
    if (this.checkIfFormIsValid()) {
      this.uploadAssetService.uploadToS3()
        .subscribe(() => {
          this.uploadAssetService.resetFileCache();
          this.router.navigate(['/activity-queue']);
        });
    }
  }

  cancel() {
    this.isFinishClicked = false
    this.router.navigate(['/']);
  }

  onSelection(template: TemplateDetails) {
    if (this.uploadAssetService.currentTemplate !== template){
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'Confirm',
          dialogContent: 'Any changes made to metadata will be lost if you change the template. Are you sure that you want to continue?',
          buttons: ["No","Yes"]
      }});
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'Yes') {
          this.updateTemplate(template);
        } else {
          this.DropDownComponent.onSelectionChange(this.uploadAssetService.currentTemplate)
        }
      });
    }
  }

  updateTemplate (template: TemplateDetails){
    this.uploadAssetService.currentTemplate = template;
    this.uploadAssetService.formInitialization();
  }
}
