import { Component, EventEmitter, Input, Output, Renderer2, ElementRef, AfterViewChecked, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'dal-shared-ui-dragdropfile',
  templateUrl: './dragdropfile.component.html',
  styleUrl: './dragdropfile.component.scss',
})
export class DragdropfileComponent implements AfterViewChecked {

  constructor(private renderer: Renderer2, private el: ElementRef, private cdr: ChangeDetectorRef) { }

  @Input() files: File[] = [];
  @Input() role: string = "";
  @Input() duplicateFiles: string[] = [];
  @Input() dragDropDisabled: boolean;
  @Input() fileType: string;
  showWarning: boolean = false;
  warningMessage: string = ''; // Custom warning message
  allowedExtensions: { [key: string]: string[] } = {
    Image: ['jpg', 'jpeg', 'png', 'gif', 'bmp'],
    Video: ['mp4', 'mov', 'avi', 'mkv', 'flv'],
    Audio: ['mp3', 'wav', 'aac', 'ogg']
  };
  @Output() uploadEvent = new EventEmitter<any>();
  dataSource: any[];
  displayedColumns: string[] = []

  ngOnInit() {
    this.displayedColumns = ['type', 'name', 'size', 'action'];
    this.refreshTableData();
  }

  refreshTableData() {
    this.dataSource = [...this.files];  // Create a new reference to the array
    this.cdr.detectChanges();  // Manually trigger change detection
  }

  ngAfterViewChecked() {
    if (this.duplicateFiles.length !== 0 ){
      for (let i = 0; i < this.duplicateFiles.length; i++) {
        const element = document.getElementById(this.duplicateFiles[i]);
        const element2 = document.getElementById('error');
        if (element) {
          element.style.borderColor = 'red';
        }
        if (element2) {
          element2.style.display = 'block';
        }
      }
    }else{
      const element2 = document.getElementById('error');
      if (element2) {
        element2.style.display = 'none';
      }
    }
  }

  onFileDropped($event: any): void {
    $event.preventDefault();  // Prevent file from opening in new tab
    $event.stopPropagation(); // Stop it from bubbling up

    if (this.dragDropDisabled) {
      this.showWarning = true;
      this.warningMessage="Please select a metadata import template first";
    }else{
      if ($event.dataTransfer) {
        const files: FileList = $event.dataTransfer.files;
        const valid_files = this.validateFiles(files);
        if (valid_files){
          this.prepareFilesList(valid_files);
        }
      }
    }
  }

  fileBrowseHandler(event: Event): void {
    if (this.dragDropDisabled) {
      this.showWarning = true;
      this.warningMessage="Please select a metadata import template first";
    } else {
      const inputElement = event.target as HTMLInputElement;
      console.log('ss',inputElement.files);
      if (inputElement?.files) {
        const valid_files = this.validateFiles(inputElement.files);
        console.log(valid_files);
        if (valid_files){
          this.prepareFilesList(valid_files);
        }
      }
      this.showWarning = false;
    }
  }

  validateFiles(files: FileList): FileList {
    this.warningMessage = '';
    this.showWarning = false;
    const allowedExts = this.allowedExtensions[this.fileType];
    const dataTransfer = new DataTransfer(); // To construct a new FileList
  
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileExt = file.name.split('.').pop()?.toLowerCase();
  
      // add check if file already exist in array
      const dupfile = this.files.findIndex(item => item.name === file.name && item.lastModified === file.lastModified);
      if (dupfile > -1) {
        this.showWarning = true;
        this.warningMessage = `${file.name} already selected`;
        console.log(`duplicate file: ${file.name}`, dupfile);
        // this.files.slice(dupfile)
      } else if (fileExt && allowedExts.includes(fileExt)) {
        dataTransfer.items.add(file); // Add valid file to DataTransfer
      } else {
        this.showWarning = true;
        this.warningMessage = `Only ${allowedExts.join(', ')} files are allowed for ${this.fileType.toLowerCase()} template selected`;
        console.log(`Invalid file: ${file.name}`);
      }

    }
  
    const validFileList = dataTransfer.files; // Final valid FileList
    console.log('Valid files:', validFileList);
    return validFileList;
  }    

  prepareFilesList(files: FileList): void {
    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];
      this.files.push(file);
    }
    this.uploadEvent.emit(this.files);
    this.refreshTableData();
  }

  deleteFile(index: number): void {
    for (let i = 0; i < this.files.length; i++) {
      const file: File = this.files[i];
      if(this.duplicateFiles.includes(this.files[i].name)){
        this.duplicateFiles = this.duplicateFiles.filter(item => item !== this.files[i].name);
      }
    }
    this.files.splice(index, 1);
    this.refreshTableData();
  }

  handleDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  handleDragEnter(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  showWarningIfDisabled(event?: Event) {
    if (this.dragDropDisabled) {
      this.showWarning = true;
      this.warningMessage="Please select a metadata import template first";
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  getFileAcceptTypes(): string {
    if (this.fileType){
      const allowedExts = this.allowedExtensions[this.fileType];
      return allowedExts.map(ext => `.${ext}`).join(',');
    }
    return '';
  }
}
