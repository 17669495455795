export const environment = {
  production: false,
  environmentName: 'nonprod',

  // Base URL for API endpoints
  apiBaseUrl: 'https://mama-api-dev.dal.nonprod.mheducation.com',

  // Okta Configuration
  oktaEnabled: true,
  oktaClientId: '0oa16vtap5dTYYqy20x8',
  oktaIssuer: 'https://mhe.okta.com/oauth2/default',
  oktaRedirectUri: 'https://mama.dal.nonprod.mheducation.com/okta-callback',
  oktaPostLogoutRedirectUri: 'https://mhe.okta.com/app/UserHome',
  oktaScopes: ['openid', 'profile', 'email'],

  // Content Search API Configuration
  contentSearchAPI: 'https://vpc-aam-contentsearch-prod-xyz123.us-east-1.es.amazonaws.com',
  contentSearchAPIAuth: 'Basic REDACTED'
};

