import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from '@dal/core';
@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private apiUrl = environment.contentSearchAPI

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': environment.contentSearchAPIAuth
    })
  };

  constructor(private http: HttpClient) { }
  getAssets(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/dev-dal/_search`, this.httpOptions).pipe(
      catchError(error => {
        // Error fetching assets
        return throwError(() => error);
      })
    );
  }

  searchByKeyword(
    keyword = '',
    termFilters: { [key: string]: string[] } = {} 
  ): Observable<any> {
    const mustQueries: any[] = [];
      if (keyword && keyword.trim() !== '*') {
      mustQueries.push({
        multi_match: {
          query: keyword,
          fields: [
            "author","characteristics","copyrightHolder","creditText","dalUniqueId","description","ethnicity","filename","gender","genre",
             "gpsUniqueId","keywords","dateLocation","alternateFileName","title","adaptationRights","ageGroup","artisticCollection","assetSource",
             "assetType","businessUnitRights","colorBw","dalAssetStatus","displayedCreditRequired","distributionRights","finalizedFlag",
              "gpsAssetStatus","gpsContractStatus","importedBy","language","licensingLimitations","locationRelease","marketingUse","metadataReviewCompleted","metadataReviewedBy",
               "metadataStatus","model","modelRelease","numberOfPeople","orientation","providerBu","retailCollection","rightsCategory","specialNeeds","usageRestrictions","videoDimensions","videoSound","volumeLabel"
          ],
          type: "best_fields",
          operator: "or",
          
         
        }
      });
    }
      if (Object.keys(termFilters).length > 0) {
      for (const [field, values] of Object.entries(termFilters)) {
        if (values.length > 0) {
          mustQueries.push({
            terms: { [field]: values }
          });
        }
      }
    }
    if (mustQueries.length === 0) {
      mustQueries.push({
        match_all: {}
      });
    }
    const requestBody = {
      query: {
        bool: {
          must: mustQueries
        }
      }
    };
    return this.http.post<any>(`${this.apiUrl}/dev-dal/_search`, requestBody, this.httpOptions).pipe(
      catchError(error => {
        console.error('Error during search by keyword:', error);
        return throwError(() => error);
      })
    );
  }
}

