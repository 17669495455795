import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'byteToMb'
})
export class ByteToMbPipe implements PipeTransform {

  transform(value: number): string {
    if (!value) return '0.00 MB'; // Handle null or undefined values gracefully
    const sizeInMB = value / (1024 * 1024); // Convert bytes to MB
    return `${sizeInMB.toFixed(2)} MB`; // Round to 2 decimal places
  }

}
