<div class="dialog-box">
  <h2 mat-dialog-title class="dialog-title">{{title}}</h2>
  <mat-dialog-content class="dialog-content flex">
    <div class="content-wrapper">
      <p>{{dialogContent}}</p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="dialog-actions" align="end">
    @for (button of buttons; track button; let i = $index) {
      <button mat-button mat-dialog-close="{{button}}" class="{{button}}-button">{{button}}</button>
    }
  </mat-dialog-actions>
</div>
