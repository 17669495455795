<mat-form-field class="custom-mat-form-field vertical-align custom-search-form-field">
  @if (showIcon){
    <mat-icon matPrefix class="">search</mat-icon>
  }
  <input class="input-text" matInput autoActiveFirstOption="false" [matAutocomplete]="auto" type="text" placeholder="{{SearchLabel}}" [(ngModel)]="searchText" (keydown.enter)="search()" />
  <mat-autocomplete #auto="matAutocomplete">
    @for (option of options; track option) {
      <mat-option [value]="option">{{option}}</mat-option>
    }
  </mat-autocomplete>
</mat-form-field>