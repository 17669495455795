<div class="toolbar flex flex-row bg-secondary">
  <div class="flex flex-row">
    <button
      mat-icon-button
      class="icon icon-menu"
      aria-label="icon-button with menu icon"
      (click)="toggleSideNav()"
    >
      <mat-icon class="text-white center-menu-icon">menu</mat-icon>
    </button>
    <a routerLink="/" class="flex flex-row">
      <img src="assets/mhe-logo.jpg" alt="mhe-logo" class="mhe-logo" />    
      <span class="text-white text-base my-auto"> {{ title }}</span>
    </a>
  </div>
  <div class="flex flex-auto flex-row justify-end">
    <div class="mx-2 flex px-2 cursor-pointer" routerLink="/">
      <div class="text-grey_c my-auto mx-[10px]">
        {{ user.name | titlecase }}
      </div>
      <span class="material-symbols-outlined my-auto text-white text-[32px]">
        account_circle
      </span>
    </div>

    <dal-shared-ui-search
      (SearchEvent)="search($event)"
      [SearchLabel]="'Search...'"
      [showIcon]="true"
    ></dal-shared-ui-search>
    
    <img
      src="assets/spinner-loader.svg"
      alt="active queue"
      class="mhe-logo -scale-x-[1]"
      (click)="toggleActivityQueue()"
    />
  </div>
</div>
<div *ngIf="showActivityQueue">
  <dal-shared-ui-activity-queue
    (close)="toggleActivityQueue()"
  ></dal-shared-ui-activity-queue>
</div>
