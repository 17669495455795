import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MetadataOption } from './../../../constants/MetadataOption.constants';
import { EditMetadataFormValidator } from './../../../constants/EditMetadataFormvalidator.constants';
import {
  AssetSourceDropdownOption,
  DropdownOption,
  ProviderBUDropdownOption,
} from '../../../models/dropdown.model';
import { UploadAssetService } from '../../../services/upload-asset.service';
import { AbsService, AssetMetadataService } from '@dal/core';

@Component({
  selector: 'dal-asset-page-edit-metadata',
  templateUrl: './AssetPage-edit-metadata.component.html',
  styleUrls: ['./AssetPage-edit-metadata.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssetPageEditMetadataComponent implements OnInit {
  form: FormGroup;
  DescriptiveOpen = true;
  InformationalOpen = true;
  RightsOpen = true;
  TechnicalOpen = true;
  AssetMetadata: any;
  assetData: any;
  MetadataOption: any = MetadataOption;
  @Input() assetDetails: any;
  @Output() formData = new EventEmitter<any>();
  @Output() formChange = new EventEmitter<any>();
  @Output() deleteUncheckedItemsEvent = new EventEmitter<void>();
  isReadOnly = true;
  constructor(private fb: FormBuilder, private absService: AbsService, private uploadAssetService: UploadAssetService, private assetMetadataService: AssetMetadataService) {}  
  dropdownValuesMap: Map<number, string[]> = new Map<number, string[]>();
  AssetSourceDropdownValues: string[] = [];
  ProviderBusinessDropdownValues: string[] = [];
  dropdownIds = [
    34, 32, 33, 49, 50, 51, 53, 37, 38, 39, 35, 55, 36, 40, 41, 56, 42, 43,
    57, 44, 45, 75, 48, 77, 78, 64, 65, 66, 67, 68, 31, 47, 46, 69, 70, 81, 79
  ];
  uncheckedItems: { key: string; value: string }[] = [];
  selectedValues: string[] = [];
  uncheckedValues: { key: string; value: string }[] = [];
  previousSelectedValuesMap: { [key: string]: string[] } = {};
  deleteArr: { key: string; value: string }[] = [];
  apiKeyMapping: { [key: string]: string } = {
    ageGroup: 'age-group',
    ethnicity: 'ethnicity',
    characteristicsImage: 'image-characteristics',
    subjectImage: 'image-subject-genre',
    providerBU: 'providerBU',
    language: 'language',
    assetSource: 'asset-source',
    businessUnitRights: 'business-unit-rights',
    distributionRights: 'distribution-rights',
    licensingLimitations: 'licensing-limitations',
    usageRestriction: 'usage-restriction',
    characteristicsVideo: 'video-characteristics',
    subjectVideo: 'video-subject-genre',
    subjectAudio: 'audio-subject-genre',
    characteristicsAudio: 'audio-characteristics',
  };
  fields: string[] = [
    'ethnicity',
    'ageGroup',
    'characteristicsImage',
    'subjectImage',
    'providerBU',
    'language',
    'assetSource',
    'businessUnitRights',
    'distributionRights',
    'licensingLimitations',
    'usageRestriction',
    'characteristicsVideo',
    'subjectVideo',
    'subjectAudio',
    'characteristicsAudio',
  ];
  ngOnInit(): void {
    this.form = this.fb.group(EditMetadataFormValidator);
    this.initializeFormWithMetadata(this.assetDetails);
    this.dropdownIds.forEach((id) => this.getDropdownValues(id));
    this.getDropdownValuesforAssetSource();
    this.getDropdownValuesforProviderBusinessUnit();
    this.fields.forEach((field) =>
      this.initializePreviousSelectedValues(field)
    );
  }
  getDropdownValues(id: number): void {
    this.absService.fetchAssetMetadataDropDownValues(id).subscribe(
      (response: any) => {
        const dropdownOptions: DropdownOption[] = response; // Assert type here
        this.dropdownValuesMap.set(
          id,
          dropdownOptions.map((item) => item.name)
        );
      },
      (error) => {
        console.error('Error fetching dropdown values:', error);
      }
    );
  }

  getDropdownValuesforAssetSource(): void {
    this.absService.fetchAssetMetadataDropDownValuesforAssetSource().subscribe(
      (response) => {
        const assetSourceDropdownOptions =
          response as AssetSourceDropdownOption[];
        this.AssetSourceDropdownValues = assetSourceDropdownOptions.map(
          (item) => item.name
        );
      },
      (error) => {
        console.error('Error fetching dropdown values:', error);
      }
    );
  }

  getDropdownValuesforProviderBusinessUnit(): void {
    this.absService
      .fetchAssetMetadataDropDownValuesforProviderBusinessUnit()
      .subscribe(
        (response) => {
          const providerBUDropdownOptions =
            response as ProviderBUDropdownOption[];
          this.ProviderBusinessDropdownValues = providerBUDropdownOptions.map(
            (item) => item.name
          );
        }
      );
  }
  initializePreviousSelectedValues(fieldName: string): void {
    const control = this.form.get(fieldName);
    if (control) {
      const initialValues = control.value || [];
      this.previousSelectedValuesMap[fieldName] = [...initialValues];
    } else {
      // no control found for the field
      this.previousSelectedValuesMap[fieldName] = [];
    }
  }
  handleUncheck(field: string, option: string): void {
    const uncheckedItem = { key: field, value: option };
    // Add to delete array
    this.deleteArr.push(uncheckedItem);
  }
  handleRecheck(option: string): void {
    this.deleteArr = this.deleteArr.filter((item) => item.value !== option);
    // Remove from delete array
  }
  onOptionChange(event: any, field: string): void {
    const selectedOptions: string[] = event.value || [];
    const previousSelectedOptions: string[] =
      this.previousSelectedValuesMap[field] || [];
    const uncheckedOptions = previousSelectedOptions.filter(
      (option) => !selectedOptions.includes(option)
    );
    if (uncheckedOptions.length > 0) {
      uncheckedOptions.forEach((uncheckedOption) => {
        this.handleUncheck(field, uncheckedOption);
        const apiKey = this.apiKeyMapping[field] || field;
        const uncheckedItem = { key: apiKey, value: uncheckedOption };
        this.uncheckedItems.push(uncheckedItem);
      });
    }
    this.previousSelectedValuesMap[field] = [...selectedOptions];
  }
  deleteUncheckedItems(): void {
    if (this.uncheckedItems.length > 0) {
      this.uncheckedItems.forEach((uncheckedItem) => {
        this.assetMetadataService
          .deleteMetadata(this.assetDetails.Descriptive.AssetID, [uncheckedItem])
          .subscribe(
          // Handle response for failed delete
        );
      });
      this.uncheckedItems = [];
    }

    this.deleteUncheckedItemsEvent.emit();
  }

  initializeFormWithMetadata(assetDetails: any) {
    console.log('ssss',assetDetails.Informational?.MediaType)
    const ageGroupValue = Array.isArray(assetDetails.Descriptive?.AgeGroup)
      ? assetDetails.Descriptive.AgeGroup
      : [assetDetails.Descriptive?.AgeGroup];

    const providerBUValue = Array.isArray(assetDetails.Informational?.ProviderBU)
      ? assetDetails.Informational.ProviderBU
      : [assetDetails.Informational?.ProviderBU];
    const languageValue = Array.isArray(assetDetails.Informational?.Language)
      ? assetDetails.Informational.Language
      : [assetDetails.Informational?.Language];
    const assetSourceValue = Array.isArray(assetDetails.Rights?.AssetSource)
      ? assetDetails.Rights.AssetSource
      : [assetDetails.Rights?.AssetSource];
    const ethnicityValue = Array.isArray(assetDetails.Descriptive?.Ethnicity)
      ? assetDetails.Descriptive.Ethnicity
      : [assetDetails.Descriptive?.Ethnicity];
    const distributionRightsValue = Array.isArray(
      assetDetails.Rights?.DistributionRights
    )
      ? assetDetails.Rights.DistributionRights
      : [assetDetails.Rights?.DistributionRights];
    const businessUnitRightsValue = Array.isArray(
      assetDetails.Rights?.BusinessUnitRights
    )
      ? assetDetails.Rights.BusinessUnitRights
      : [assetDetails.Rights?.BusinessUnitRights];
    const usageRestrictionValue = Array.isArray(
      assetDetails.Rights?.UsageRestriction
    )
      ? assetDetails.Rights.UsageRestriction
      : [assetDetails.Rights?.UsageRestriction];
    const licensingLimitationsValue = Array.isArray(
      assetDetails.Rights?.LicensingLimitations
    )
      ? assetDetails.Rights.LicensingLimitations
      : [assetDetails.Rights?.LicensingLimitations];

    const characteristicsImageValue = Array.isArray(
      assetDetails.Descriptive?.CharacteristicsImage
    )
      ? assetDetails.Descriptive.CharacteristicsImage
      : [assetDetails.Descriptive?.CharacteristicsImage];

    const characteristicsVedioValue = Array.isArray(
      assetDetails.Descriptive?.CharacteristicsVideo
    )
      ? assetDetails.Descriptive.CharacteristicsVideo
      : [assetDetails.Descriptive?.CharacteristicsVideo];

    const subjectVideoValue = Array.isArray(
      assetDetails.Descriptive?.VideoSubjectGenre
    )
      ? assetDetails.Descriptive.VideoSubjectGenre
      : [assetDetails.Descriptive?.VideoSubjectGenre];

    const subjectAudioValue = Array.isArray(
      assetDetails.Descriptive?.AudioSubjectGenre
    )
      ? assetDetails.Descriptive.AudioSubjectGenre
      : [assetDetails.Descriptive?.AudioSubjectGenre];

    const characteristicsAudioValue = Array.isArray(
      assetDetails.Descriptive?.CharacteristicsAudio
    )
      ? assetDetails.Descriptive.CharacteristicsAudio
      : [assetDetails.Descriptive?.CharacteristicsAudio];

    const imageSubjectGenreValue = Array.isArray(
      assetDetails.Descriptive?.ImageSubjectGenre
    )
      ? assetDetails.Descriptive.ImageSubjectGenre
      : [assetDetails.Descriptive?.ImageSubjectGenre];

    if (assetDetails) {
      this.form.patchValue({
        title: assetDetails.Descriptive.Title || '',
        assetId: assetDetails.Descriptive?.AssetID || '',
        description: assetDetails.Descriptive?.Description || '',
        temporalSpatial: assetDetails.Descriptive?.TemporalSpatial || '',
        keywords: assetDetails.Descriptive?.Keyword || '',
        ageGroup: ageGroupValue,
        filename: assetDetails.Informational?.Filename || '',
        dalID: assetDetails.Informational?.DalID || '',
        mediatype: assetDetails.Informational?.MediaType || '',
        ethnicity: ethnicityValue,
        gender: assetDetails.Descriptive?.Gender || '',
        disability: assetDetails.Descriptive?.Disability || '',
        metadataStatus: assetDetails.Descriptive?.MetadataStatus || '',
        finalizedFlag: assetDetails.Descriptive?.FinalizedFlag || '',
        metadataReviewedBy: assetDetails.Descriptive?.MetadataReviewedBy || '',
        metadataReviewCompleted:
          assetDetails.Descriptive?.MetadataReviewCompleted || '',
        orientation: assetDetails.Descriptive?.Orientation || '',
        colorBWImage: assetDetails.Descriptive?.ImageColor || '',
        imageType: assetDetails.Descriptive?.ImageType || '',
        characteristicsImage: characteristicsImageValue,
        subjectImage: imageSubjectGenreValue,
        colorBWVideo: assetDetails.Descriptive?.ColorBWVideo || '',
        videoType: assetDetails.Descriptive?.VideoType || '',
        characteristicsVideo: characteristicsVedioValue,
        subjectVideo: subjectVideoValue,
        videoSound: assetDetails.Descriptive?.VideoSound || '',
        audioType: assetDetails.Descriptive?.AudioType || '',
        subjectAudio: subjectAudioValue,
        characteristicsAudio: characteristicsAudioValue,
        metadatarevieweddate: [
          assetDetails.Descriptive?.MetadataReviewedDate || '',
        ],
        numberOfPeople: assetDetails.Descriptive?.NumberOfPeople || '',
        lastModifiedDate: assetDetails.Informational?.LastModifiedDate || '',
        mediaType: assetDetails.Informational?.MediaType || '',
        alternateFileName: assetDetails.Informational?.AlternateFileName || '',
        sourceFilename: assetDetails.Informational?.SourceFilename || '',
        assetCreator: assetDetails.Informational?.AssetCreator || '',
        retailCollection: assetDetails.Informational?.RetailCollection || '',
        volumeLabel: assetDetails.Informational?.VolumeLabel || '',
        artisticCollection:
          assetDetails.Informational?.ArtisticCollection || '',
        providerBU: providerBUValue,
        language: languageValue,
        dateImported: assetDetails.Informational?.DateImported || '',
        importedBy: assetDetails.Informational?.ImportedBy || '',
        lastModifiedBy: assetDetails.Informational?.LastModifiedBy || '',
        adaptationRights: assetDetails.Rights?.AdaptationRights || '',
        assetSource: assetSourceValue,
        notes: assetDetails.Rights?.Notes || '',
        rightCategory: assetDetails.Rights?.RightsCategory || '',
        copyrightHolder: assetDetails.Rights?.CopyrightHolder || '',
        displayedCreditRequired:
          assetDetails.Rights?.DisplayedCreditRequired || '',
        creditText: assetDetails.Rights?.CreditText || '',
        extendedCreditRequired:
          assetDetails.Rights?.ExtendedCreditRequired || '',
        extendedCreditText: assetDetails.Rights?.ExtendedCreditText || '',
        locationRelease: assetDetails.Rights?.LocationRelease || '',
        modelRelease: assetDetails.Rights?.ModelReleaseName || '',
        gps: assetDetails.Rights?.GPSUniqueID || '',
        gpsAssetStatus: assetDetails.Rights?.GpsAssetStatus || '',
        contractStatus: assetDetails.Rights?.GpsContractStatus || '',
        businessUnitRights: businessUnitRightsValue,
        distributionRights: distributionRightsValue,
        marketingUse: assetDetails.Rights?.MarketingUse || '',
        usageRestriction: usageRestrictionValue,
        dalAssetStatus: assetDetails.Rights?.DalAssetStatus || '',
        licensingLimitations: licensingLimitationsValue,
        mimetype: assetDetails.Technical?.MimeType || '',
        bitmapColorMap: assetDetails.Technical?.BitmapColorMap || '',
        aspectRatio: assetDetails.Technical?.AspectRatio || '',
        frameRate: assetDetails.Technical?.FrameRateVideo || '',
        audioTracksVideo: assetDetails.Technical?.AudioTracksVideo || '',
        audioCodecNameVideo: assetDetails.Technical?.AudioCodecNameVideo || '',
        audioSampleRateVideo:
          assetDetails.Technical?.AudioSampleRateVideo || '',
        audioSampleSizeVideo:
          assetDetails.Technical?.AudioSampleSizeVideo || '',
        bitRateVideo: assetDetails.Technical?.BitRateVideo || '',
        bitmapColorDepthImage:
          assetDetails.Technical?.BitmapColorDepthImage || '',
        bitmapHeight: assetDetails.Technical?.BitmapHeight || '',
        bitmapOrientation: assetDetails.Technical?.BitmapOrientation || '',
        bitmapResolutionImage:
          assetDetails.Technical?.BitmapResolutionImage || '',
        bitmapWidth: assetDetails.Technical?.BitmapWidth || '',
        durationAudio: assetDetails.Technical?.DurationAudio || '',
        durationVideo: assetDetails.Technical?.DurationVideo || '',
        fileSize: assetDetails.Technical?.FileSize || '',
        frameHeightVideo: assetDetails.Technical?.FrameHeight || '',
        frameWidthVideo: assetDetails.Technical?.FrameWidth || ' ',
        frameRateVideo: assetDetails.Technical?.FrameRate || ' ',
        numberofAudioTracksVideo:
          assetDetails.Technical?.AudioTracksVideo || '',
        numberofVideoTracksVideo:
          assetDetails.Technical?.VideoTracksVideo || '',
        videoCodec: assetDetails.Technical?.VideoCodecName || '',
        videoDimension: assetDetails.Technical?.VideoDimension || '',
      });
    }
  }

  toggleDropdown(section: string) {
    if (section === 'descriptive') {
      this.DescriptiveOpen = !this.DescriptiveOpen;
    } else if (section === 'informational') {
      this.InformationalOpen = !this.InformationalOpen;
    } else if (section === 'rights') {
      this.RightsOpen = !this.RightsOpen;
    } else if (section === 'technical') {
      this.TechnicalOpen = !this.TechnicalOpen;
    }
  }

  addKeyword(event: KeyboardEvent, metadataField: string): void {
    const input = event.target as HTMLInputElement;
    const enteredValue = input.value.trim();

    if (event.key === 'Enter' && enteredValue) {
      const currentValue: string[] = this.form.get(metadataField)?.value || [];
      if (!currentValue.includes(enteredValue)) {
        this.form.get(metadataField)?.setValue([...currentValue, enteredValue]);
      }
      input.value = ''; // Clear the input field
    }
  }

  // Remove a keyword
  removeKeyword(keyword: string, metadataField: string): void {
    const currentValue: string[] = this.form.get(metadataField)?.value || [];
    this.form.get(metadataField)?.setValue(currentValue.filter(k => k !== keyword));
  }

  editMetadataField(event: KeyboardEvent, metadataField: string) {
    const metadataValue = (event.target as HTMLInputElement).value.trim();
    
    // Update metadata field
    const updatedMetadata = { ...this.assetDetails };
    updatedMetadata[metadataField] = metadataValue;
    this.formChange.emit(updatedMetadata); // Emit form change event
  }
  compareFn(option1: any, option2: any): boolean {
    return option1 && option2
      ? option1 === option2
      : option1 === null && option2 === null;
  }

  updateMetadataField(
    metadata: any,
    metadataField: string,
    metadataValue: any
  ): any {
    // Split metadataField into nested keys
    const keys = metadataField.split('.');
    let temp = metadata;

    // Traverse through the nested structure to find the field to update
    for (let i = 0; i < keys.length - 1; i++) {
      temp = temp[keys[i]];
    }

    // Update the specific field
    temp[keys[keys.length - 1]] = metadataValue;

    return metadata;
  }
  editMetadataArray(metadataValue: string, metadataString: string[]) {
    const index = metadataString.indexOf(metadataValue);
    if (index === -1) {
      metadataString.push(metadataValue);
    } else {
      metadataString.splice(index, 1);
    }
  }

  removeSelectedOption(option: string, fieldName: string): void {
    const control = this.form.get(fieldName);
    if (control) {
      const currentValues = control.value || [];
      const updatedValues = currentValues.filter((value: string) => value !== option);
      control.setValue(updatedValues);
    }
  }

  editMetadataArrays(metadataValue: string, metadataString: string[], id: any) {
    const index = metadataString.indexOf(metadataValue);
    if (index === -1) {
      metadataString.push(metadataValue);
    } else {
      metadataString.splice(index, 1);
    }
    this.getDropdownValues(id);
  }

  setdate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    this.AssetMetadata.Metadata.MetadataReviewedDate = formattedDate;
  }

  // Public method to get the form value
  getFormValue() {
    return this.form.value;
  }
}
