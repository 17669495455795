import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dal-shared-ui-dialog',
  styleUrl: './dialog.component.scss',
  templateUrl: './dialog.component.html',
})
export class DialogComponent {
  
  @Input() dialogContent: string;
  @Input() title: string;
  @Input() buttons: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.dialogContent = data.dialogContent;
    this.buttons = data.buttons;
  }
}
