import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, forkJoin, from, map, mergeMap, Observable, of, retry, switchMap, tap, throwError } from 'rxjs';
import { AbsService, AssetMetadataService, AssetService, S3Service } from '@dal/core';
import { CreateABSAsset, TemplateDetails } from '../models/asset.metadata.model';
import { UtilService } from './util.service';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import * as ExifReader from 'exifreader';


@Injectable({
  providedIn: 'root'
})
export class UploadAssetService {

  private _currentTemplate: TemplateDetails;
  private _files: File[] = [];
  assetPayload: any[] = [];
  assetPreviewForm: FormGroup;
  metadataForm: FormGroup;
  assetIndex = 0
  private assetIndexSubject = new BehaviorSubject<number>(this.assetIndex);
  assetIndex$ = this.assetIndexSubject.asObservable();


  constructor(
    private absService: AbsService,
    private assetService: AssetService,
    private utilService: UtilService,
    private s3Service: S3Service,
    private fb: FormBuilder,
    private assetMetadataService: AssetMetadataService,
  ) { }

  get currentTemplate(): TemplateDetails {
    // const storedTemplate = sessionStorage.getItem('currentTemplate');
    // return storedTemplate ? JSON.parse(storedTemplate) : this._currentTemplate;
    return this._currentTemplate;
  }

  set currentTemplate(template: TemplateDetails) {
    // sessionStorage.setItem('currentTemplate', JSON.stringify(template));
    this._currentTemplate = template;
  }

  get files(): File[] {
    return this._files;
  }

  set files(files: File[]) {
    this._files.push(...files);
  }


  duplicateCheck(): Observable<File[]> {
    return forkJoin(
      this.files.map(file => {
        const hashed = this.utilService.computeMd5Hash(file.name);
        return this.absService.duplicateCheck(hashed).pipe(
          switchMap(response => {
            const uuid = response.result.asset.id;
            return this.assetMetadataService.getMetadataById(uuid).pipe(
              switchMap(asset => {
                const isDuplicate = this.currentTemplate.source === asset.asset.asset_source.name;
                return of(isDuplicate ? file : null);
              })
            );
          }),
          catchError(error => {
            if ((error as any).status === 404 || (error as any).status === 500) {
              return of(null);
            }
            return throwError(() => error);
          })
        );
      })
    ).pipe(
      map((results: any) => results.filter((file: any) => file !== null))
    );
  }

  initAssetPayload(metadata: any[] = []) {
    this.assetPayload = this.files.map((file, index) => {
      return {
        file, metadata: metadata[index]
      }
    })
  }

  setAssetIndex(newIndex: number): void {
    this.assetIndex = newIndex;
    this.assetIndexSubject.next(this.assetIndex);
  }

  private getImageUrl(file: File): string {
    return URL.createObjectURL(file);
  }

  formInitialization(): void {
    this.metadataForm = this.fb.group({
      "assetSource": [this.currentTemplate.template_id, Validators.required],
      "rights-category": [this.currentTemplate.rights_category, Validators.required],
      "adaptation-rights": [this.currentTemplate.adaption_rights, Validators.required],
      "business-unit-rights": [null],
      "credit-text": [null],
      "model-release": [null],
      "location-release": [null],
      "usage-restriction": [null],
      "notes": [null],
      "distribution-rights": [null],

      "providerBU": [this.currentTemplate.provider_bu_id, Validators.required],
      "asset-creator": [null],
      "retail-collection": [null],
      "volume-label": [null],

      "media-type": [this.currentTemplate.model, Validators.required],
      "image-color-or-bw": [null],
      "video-color-or-bw": [null],
      "disability": [null],
      "video-sound": [null],
    })
    this.assetPreviewForm = this.fb.group({
      files: this.fb.array([]),
    });

    this.assetPayload.map(asset => {
      return { ...asset, blob: this.getImageUrl(asset.file) }
    }).map(asset => this.addItem(asset))
  }

  private addItem(value: any): void {
    const files = this.assetPreviewForm.get(
      'files'
    ) as FormArray;
    files.push(this.createItem(value));
  }

  private createItem({ file, blob, metadata }: { file: File, blob: string, metadata: any }): FormGroup {
    return this.fb.group({
      fileName: [{ value: file.name, disabled: true }, Validators.required,],
      title: [metadata.title, Validators.required],
      src: [blob, Validators.required],
    });
  }

  // upload calls
  uploadToS3() {
    const fileNames = this.files.map((file: any) => file.name);

    return this.s3Service.getS3PresignedUrl(fileNames)
      .pipe(
        switchMap((presignedUrls: any[]) => {
          const presignedUrlsPath = presignedUrls.map((url) => url.path);
          return forkJoin(
            this.files.map((file: File, index: number) => this.uploadFileWithRetry(file, presignedUrlsPath[index]))
          ).pipe(map((uploadResults) => ({ uploadResults, presignedUrlsPath })))
        }),
        switchMap(({ presignedUrlsPath }) => {
          const assetData: CreateABSAsset[] = this.buildAssetData(presignedUrlsPath);
          return this.createAsset(assetData);
        })
      );
  }

  // Function to build asset payload based on the uploaded files and form data
  private buildAssetData(s3Paths: string[]): CreateABSAsset[] {
    return this.assetPayload.map((asset: any, index: number) => ({
      title: this.assetPreviewForm.value.files[index].title,
      filename: asset.file.name,
      asset_source_id: this.metadataForm.value.assetSource,
      provider_bu_id: this.metadataForm.value.providerBU,
      asset_type_id: this.getAssetTypeId(index),
      s3_path: s3Paths[index],
      metadata: Object.keys(this.metadataForm.value)
        .filter(key => this.metadataForm.value[key] !== null)
        .filter(key => key !== 'assetSource' && key !== 'providerBU')
        .map(key => ({
          key: key,
          value: Array.isArray(this.metadataForm.value[key]) ? this.metadataForm.value[key] : [this.metadataForm.value[key]]
        }))
    }));
  }

  private getAssetTypeId(index: number) {
    const fileType = this.files[index].type;
    if (fileType.includes('image')) {
      return 1;
    } else if (fileType.includes('video')) {
      return 2;
    } else if (fileType.includes('audio')) {
      return 3;
    } else {
      return 1; // Default or unknown type
    }
  }

  private createAsset(assetData: any[]): Observable<any> {
    return this.assetService.createAssetBulk(assetData).pipe(
      catchError(err => {
        console.error("Error pushing to ABS:", err);
        return of(err);
      })
    );
  }

  // File upload logic with retry on failure or expired URLs
  private uploadFileWithRetry(file: File, url: string): Observable<any> {
    return this.s3Service.pushToPresignedUrl(file, url).pipe(
      // Retry logic for failed uploads
      retry({ count: 3, delay: 1000 }),
      catchError(err => {
        if (this.isExpiredUrlError(err)) {
          return this.retryUpload(file);
        } else {
          return of(err); 
        }
      })
    );
  }

  // Retry logic for expired URLs
  private retryUpload(file: File): Observable<any> {
    return this.s3Service.getS3PresignedUrl([file.name]).pipe(
      switchMap((presignedUrls: any[]) => {
        const newUrl = presignedUrls[0]?.path;
        if (!newUrl) {
          throw new Error(`Failed to get a new URL for file: ${file.name}`);
        }
        return this.s3Service.pushToPresignedUrl(file, newUrl);
      }),
      catchError(err => {
        return of(err); 
      })
    );
  }

  // Helper function to detect expired presigned URL errors
  private isExpiredUrlError(error: any): boolean {
    return error.status === 400 && error?.message?.includes('Expired');
  }


  extractedTagsMetadata() {
    return Promise.all(this.files.map(async (file) => {
      try {
        if (file.type.toLowerCase().includes('image')) {
          const tags = await this.readFileMetadata(file);
          return {
            title: tags[0]["Headline"] ? tags[0]["Headline"].description : '',
            description: tags[0]["description"] ? tags[0]["description"].description : '',
            type: tags[0]["FileType"] ? tags[0]["FileType"].description : '',
            asset_type: tags[0]["format"] ? tags[0]["format"].description : '',
          };
        } else {
          return {
            title: '',
            description: '',
            asset_type: '',
            type: file.type,
          }
        }
      }
      catch (error) {
        return {
          title: '',
          description: '',
          asset_type: '',
          type: file.type,
        };
      }
    }));
  }

  readFileMetadata(file: File) {
    const promises: Promise<{ [key: string]: any }>[] = [];
    const promise = new Promise<{ [key: string]: any }>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target) {
          const tags = ExifReader.load(event.target.result as ArrayBuffer);
          resolve(tags);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
    promises.push(promise);
    return Promise.all(promises);
  }

  resetFileCache() {
    this.assetPayload = [];
    this._files = [];
    this.assetIndex = 0;
    this.assetIndexSubject.next(this.assetIndex);
  }
}

