import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  private baseUrl = environment.apiBaseUrl

  constructor(private http: HttpClient) { }

  // Get all metadata types
  getAllMetadataTypes(): Observable<any> {
    const url = `${this.baseUrl}/metadata`;
    return this.http.get(url);
  }

  // Get all metadata templates
  getAllMetadataTemplates(): Observable<any> {
    const url = `${this.baseUrl}/metadata/templates`;
    return this.http.get(url);
  }

  // Get metadata template by ID
  getMetadataTemplateById(id: number): Observable<any> {
    const url = `${this.baseUrl}/metadata/templates/${id}`;
    return this.http.get(url);
  }

  // Apply metadata template to a list of assets
  applyMetadataTemplate(id: number, assetIds: number[]): Observable<any> {
    const url = `${this.baseUrl}/metadata/templates/${id}/apply`;
    return this.http.post(
      url,
      { asset_ids: assetIds }
    );
  }

  // Get metadata type values by ID
  getMetadataValuesByTypeId(id: number): Observable<any> {
    const url = `${this.baseUrl}/metadata/${id}/values`;
    return this.http.get(url);
  }

  getMetadataFieldKeyMapping(value: string): string | null {
    const fieldMappings: { [key: string]: string } = {
      title: 'title',
      adaptationRights: 'adaptation-rights',
      description: 'description',
      ageGroup: 'age-group',
      numberOfPeople: 'number-of-people',
      keywords: 'keywords',
      volumeLabel: 'volume-label',
      videoType: 'video-type',
      videoSound: 'video-sound',
      videoDimension: 'video-dimension',
      videoCodec: 'video-codec-name',
      usageRestriction: 'usage-restriction',
      temporalSpatial: 'temporal-spatial',
      subjectImage: 'image-subject-genre',
      subjectAudio: 'audio-subject-genre',
      subjectVideo: 'video-subject-genre',
      rightCategory: 'rights-category',
      alternateFileName:'alternate-file-name',
      retailCollection: 'retail-collection',
      orientation: 'orientation',
      numberofVideoTracksVideo: 'num-video-tracks',
      numberofAudioTracksVideo: 'num-audio-tracks',
      modelRelease: 'model-release',
      notes: 'notes',
      mimetype: 'mime-type',
      metadataStatus: 'metadata-status',
      metadataReviewedBy: 'metadata-reviewed-by',
      metadataReviewCompleted: 'metadata-review-completed',
      mediaType: 'media-type',
      marketingUse: 'marketing-use',
      locationRelease: 'location-release',
      licensingLimitations: 'licensing-limitations',
      providerBU: 'provider-bu',
      language: 'language',
      imageType: 'image-type',
      gpsAssetStatus: 'gps-asset-status',
      gps: 'gps',
      gender: 'gender',
      frameWidthVideo: 'frame-width',
      frameRateVideo: 'frame-rate-video',
      frameRate: 'frame-rate',
      frameHeightVideo: 'frame-height',
      finalizedFlag: 'finalized',
      fileSize: 'file-size',
      extendedDescription: 'extended-description',
      extendedCreditRequired: 'extended-credit-required',
      ethnicity: 'ethnicity',
      durationVideo: 'video-duration',
      durationAudio: 'audio-duration',
      distributionRights: 'distribution-rights',
      displayedCreditRequired: 'displayed-credit-required',
      disability: 'disability',
      dalAssetStatus: 'dal-asset-status',
      creditText: 'credit-text',
      extendedCreditText: 'extended-credit-text',
      copyrightHolder: 'copyright-holder',
      contractStatus: 'gps-contract-status',
      colorBWVideo: 'video-color-or-bw',
      colorBWImage: 'image-color-or-bw',
      characteristicsVideo: 'video-characteristics',
      characteristicsImage: 'image-characteristics',
      characteristicsAudio: 'audio-characteristics',
      businessUnitRights: 'business-unit-rights',
      bitmapWidth: 'bitmap-width',
      bitmapResolutionImage: 'bitmap-resolution',
      bitmapOrientation: 'bitmap-orientation',
      bitmapHeight: 'bitmap-height',
      bitmapColorMap: 'bitmap-color-map',
      bitmapColorDepthImage: 'bitmap-color-depth',
      bitRateVideo: 'bitrate-video',
      audioType: 'audio-type',
      audioTracksVideo: 'audio-tracks-video',
      audioSampleSizeVideo: 'audio-sample-size-video',
      audioSampleRateVideo: 'audio-sample-rate',
      audioCodecNameVideo: 'audio-codec-name',
      assetSource: 'asset-source',
      assetCreator: 'asset-creator',
      aspectRatio: 'aspect-ratio',
      artisticCollection: 'artistic-collection'
    };
  
    return fieldMappings[value] || null; // Return mapped value or original value if not found
  }

  keysToDelete = [
    "temporal-spatial",
    "keywords",
    "notes",
    "alternate-file-name",
    "age-group",
    "ethnicity",
    "image-characteristics",
    "image-subject-genre",
    "audio-subject-genre",
    "video-subject-genre",
    "audio-characteristics",
    "video-characteristics",
    "provider-bu",
    "language",
    "asset-source",
    "business-unit-rights",
    "usage-restriction",
    "licensing-limitations",
    "distribution-rights"];

  // Function to check changes for specific keys
  prepareDeletePayloadForSpecificKeys(
    assetDALId: string
  ): any[] {
    return [
      {
        asset_id: assetDALId,
        metadata:  this.keysToDelete.map((item) => ({
                key: item
          })
        ),
      }
    ];
  }

  transformFormDataToStructure(form: any, assetID: number): any[] {
    // Map the values from the form data to the new structure
    const transformedData = [
      {
        asset_id: assetID,
        gps_id: form.gps || null,
        title: form.title || null,
        filename: form.filename || null,
        asset_type_id: form.mediaType === "Image" ? 1 : form.mediaType === "Video" ? 2 : form.mediaType === "Audio" ? 3 : null, // Example mapping for asset_type_id
      },
    ];
  
    return transformedData;
  }

  transformFormData(form: any, assetId: number, createdBy: string = ''): any {
    const metadata: Array<{ key: string; value: any }> = [];

    Object.entries(form).forEach(([key, value]) => {
      const mappedKey = this.getMetadataFieldKeyMapping(key); // Map the key
      if (mappedKey) {
        // Include only if key exists in mapping and value is valid
        if (value !== null && value !== undefined) {
          if (Array.isArray(value)) {
            const filteredArray = value.filter(
              (item) => item !== null && item !== undefined
            );
            if (filteredArray.length > 0) {
              metadata.push({
                key: mappedKey,
                value: filteredArray,
              });
            }
          } else {
            metadata.push({
              key: mappedKey,
              value: [value],
            });
          }
        }
      }
    });

    return [
      {
        asset_id: assetId,
        created_by: createdBy,
        metadata: metadata,
      },
    ];
  }
  
}
