import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AbsService {
  private baseUrl: string = environment.apiBaseUrl;
  constructor(private http: HttpClient) { }

  duplicateCheck(hash: string) {
    return this.http.get<any>(`${this.baseUrl}/abs/asset/duplicate/${hash}`);
  }

  getAsset(uuid: any) {
    return this.http
      .get(`${this.baseUrl}/abs/asset/${uuid}`)
  }

  fetchAssetMetadataDropDownValues(id: number) {
    return this.http
      .get(`${this.baseUrl}/metadata/${id}/values`)

  }

  fetchAssetMetadataDropDownValuesforAssetSource() {
    return this.http
      .get(`${this.baseUrl}/assets/sources`)

  }

  fetchAssetMetadataDropDownValuesforProviderBusinessUnit() {
    return this.http
      .get(`${this.baseUrl}/business-unit`)

  }

  saveAssetDetails(data: any) {
    return this.http.put<any>(`${this.baseUrl}/assets/update-bulk`, data);
  }

  saveAssetMetadataDetails(data: any) {
    return this.http.post<any>(`${this.baseUrl}/asset-metadata`, data);
  }

  deleteAssetMetadataDetails(data: any) {
    return this.http.delete<any>(`${this.baseUrl}/asset-metadata`, {body: data});
  }
}

