import { Validators } from '@angular/forms';


export const EditMetadataFormValidator = {
  title:[],
  description: [],
  extendedDescription: [],
  temporalSpatial: [],
  keywords: [],
  numberOfPeople: [],
  ageGroup: [],
  ethnicity: [],
  gender: [],
  disability: [],
  metadataStatus: [],
  finalizedFlag: [],
  metadataReviewedBy: [],
  metadataReviewCompleted: [],
  colorBWImage: [],
  orientation: [],
  imageType: [],
  characteristicsImage: [],
  subjectImage: [],
  colorBWVideo: [],
  videoType: [],
  characteristicsVideo: [],
  subjectVideo: [],
  videoSound: [],
  audioType: [],
  subjectAudio: [],
  characteristicsAudio: [],
  filename: ['',Validators.required],
  dalID: ['',Validators.required],
  mediaType: ['', Validators.required],
  alternateFileName: [''],
  sourceFilename: [''],
  assetCreator: [],
  retailCollection:[''],
  volumeLabel:[''],
  artisticCollection: [],
  providerBU: [''],
  language: [],
  dateImported: [],
  importedBy: [],
  lastModifiedDate: [],
  lastModifiedBy: [],
  metadataLastModifiedBy : [],
  adaptationRights:['', Validators.required],
  assetSource: ['', Validators.required],
  notes: [],
  rightCategory: ['', Validators.required],
  copyrightHolder: [],
  displayedCreditRequired: [],
  creditText: [],
  extendedCreditRequired: [],
  extendedCreditText: [],
  locationRelease: [],
  modelRelease: [],
  gps:[''],
  gpsAssetStatus: [''],
  contractStatus: [''],
  businessUnitRights: [],
  distributionRights:[''],
  marketingUse: [],
  usageRestriction: [''],
  dalAssetStatus: [''],
  licensingLimitations: [],
  mimetype: [],
  bitmapColorMap: [],
  aspectRatio: [],
  frameRate: [],
  audioTracksVideo: [],
  audioCodecNameVideo: [],
  audioSampleRateVideo: [],
  audioSampleSizeVideo: [],
  bitRateVideo: [],
  bitmapColorDepthImage: [],
  bitmapHeight: [],
  bitmapOrientation: [],
  bitmapResolutionImage: [],
  bitmapWidth: [],
  durationAudio: [],
  durationVideo: [],
  fileSize: [],
  frameHeightVideo: [],
  frameWidthVideo: [],
  frameRateVideo: [],
  numberofAudioTracksVideo: [],
  numberofVideoTracksVideo: [],
  videoCodec: [],
  videoDimension: [],
}
