<div class="relative overflow-hidden">
  <!-- Upper Content Section -->
  <div class="content-scrollable overflow-y-auto">
    @if(files.length > 0) {
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Type </th>
          <td mat-cell *matCellDef="let file"> {{file.type}} </td>
        </ng-container>
      
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let file"> {{file.name}} </td>
        </ng-container>
      
        <!-- Size Column -->
        <ng-container matColumnDef="size">
          <th mat-header-cell *matHeaderCellDef> Size </th>
          <td mat-cell *matCellDef="let file"> {{file.size | byteToMb}} </td>
        </ng-container>
      
        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> &nbsp; </th>
          <td mat-cell *matCellDef="let file; let i = index"> 
            <span class="delete-button-wrapper text-primary cursor-pointer" (click)="deleteFile(i)">
              <mat-icon matTooltip="Remove File" matTooltipPosition="above" aria-label="Remove file button">close</mat-icon>
            </span> 
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    }
  </div>

  <div class="sticky bottom-0 w-full text-white">
    <div
      [ngClass]="{'disabled': dragDropDisabled}"
      class="drag-drop-container text-center flex flex-col justify-center align-center text-primary border-2 border-solid border-grey_medium bg-grey_light"
      (drop)="onFileDropped($event)" (dragover)="handleDragOver($event)" (click)="showWarningIfDisabled()" (dragenter)="handleDragEnter($event)">

      <div>
        <i class="fas fa-cloud-upload fa-2x justify-center" aria-hidden="true"></i>
      </div>
      <div class="flex justify-center">
        <label for="fileInput" class="flex flex-column text-primary text-2xl items-center" [class.disabled]="dragDropDisabled">
          Drag and drop files here or &nbsp;
          <a href="#" [class.disabled]="dragDropDisabled" [attr.disabled]="dragDropDisabled ? true : null" class="text-primary underline" (click)="showWarningIfDisabled($event); fileInput.click(); $event.preventDefault();">Browse</a>
        </label>
        <input #fileInput type="file" hidden (change)="dragDropDisabled ? showWarningIfDisabled() : fileBrowseHandler($event)" multiple [attr.disabled]="dragDropDisabled ? true : null" [attr.accept]="getFileAcceptTypes()">
      </div>
      @if(showWarning) {
        <div class="warning-message text-red-500 mt-2 text-center">
          {{warningMessage}}
        </div>
      }
    </div>

    <div id="error" class="flex justify-between items-center mt-[10px] error-dragdrop">
      <span>One or more assets with names </span>
        @for (file of duplicateFiles; track file; let i = $index) {
        [{{file}} @if(i < duplicateFiles.length-1) { , }]
        }
      <span> already exist in the system. Please rename them and upload again.</span>
      @if(role == "PA") {<p>Do you want to override? To Import anyway Please click on upload button again.</p>}
    </div>
  </div>
</div>
