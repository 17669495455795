import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UploadAssetService } from '../../services/upload-asset.service';
import { map, Observable } from 'rxjs';
import { TemplateDetails } from '../../models/asset.metadata.model';
import { MetadataService } from '@dal/core';


@Component({
  selector: 'dal-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  files: File[] = [];
  duplicateFiles: any[] = [];
  dragDropDisabled: boolean = true;
  fileType: string;
  heading: string = "DAL IMPORT TEMPLATES";
  label: string = "Select a DAL Import Template";
  templates$: Observable<TemplateDetails[]>;
  role: any = sessionStorage.getItem('role');
  forceSubmit: boolean;
  dropDown: boolean = true;

  constructor(
    private router: Router,
    private _metadataService: MetadataService,
    public uploadAssetService: UploadAssetService
  ) { }


  ngOnInit(): void {
    this.templates$ = this._metadataService.getAllMetadataTemplates().pipe(map(templates => {
      return templates.map((template: TemplateDetails) => {
        return {
          ...template,
          template_id: template.id,
          template_name: template.source + ' ' + template.name + ' ' + template.bu_name,
        };
      })
    }));
  }

  onUpload(files: File[]) {
    this.uploadAssetService.files = files.filter(file => {
      const dupfile = this.uploadAssetService.files.findIndex(item => item.name === file.name && item.lastModified === file.lastModified);
      return dupfile === -1
    })
    this.forceSubmit = false
  }

  onSelection(template: TemplateDetails) {
    if (this.uploadAssetService.currentTemplate !== template){
      this.uploadAssetService.currentTemplate = template;
      this.files = [];
      this.fileType = template.model;
      this.dragDropDisabled = false;
    }
  }

  onSubmit() {
    if (this.disableSubmit()) return
    this.uploadAssetService.duplicateCheck().subscribe(async (files) => {
      this.duplicateFiles = files;
      if (this.duplicateFiles.length > 0 && !this.forceSubmit && this.role == 'PA') {
        this.forceSubmit = true
      } else {
        const extractedTagsMetadata = await this.uploadAssetService.extractedTagsMetadata()

        this.uploadAssetService.initAssetPayload(extractedTagsMetadata)
        this.router.navigate(['/upload']);
      }
    });

  }

  disableSubmit() {
    return this.uploadAssetService.files.length === 0 || this.uploadAssetService.currentTemplate === undefined
  }
  onCancel() {
    this.files = [];
    this.forceSubmit = false;
    this.uploadAssetService.resetFileCache();
  }
}